import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface RouteState {
  page: string;
  showOnlyContent: boolean;
}

const initialState: RouteState = {
  page: 'home',
  showOnlyContent: false
};

export const routeSlice = createSlice({
  name: 'router',
  initialState,
  reducers: {
    goToPage: (state, action: PayloadAction<string>) => {
      state.page = action.payload;
    },
    setShowOnlyContent: (state, action: PayloadAction<boolean>) => {
      state.showOnlyContent = action.payload;
    },
  },
});

export const { goToPage, setShowOnlyContent } = routeSlice.actions;

export const routeReducer = routeSlice.reducer;
