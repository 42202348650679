import { CaretRight, RubleSign } from 'src/shared/icons';
import { Col, List, Row, Typography } from 'antd';
import { ListComponent } from 'src/shared/components/List/List';
import { SpecialtyUI } from './Specialty.mapper';
import { Specialty, WidgetServiceRequestType } from 'src/shared/types';
import { getDoctorsWithLabel } from 'src/shared/utils';
import { goToPage } from 'src/shared/redux/routing/routingSlice';
import { setServiceRequestType, setSpecialty } from 'src/shared/redux/appointment/appointmentSlice';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';

interface SpecialtiesListProps {
  specialties: Specialty[];
  serviceType: WidgetServiceRequestType;
}

export const SpecialtiesList = ({ specialties, serviceType }: SpecialtiesListProps) => {
  const dispatch = useAppDispatch();
  const showChildren = useAppSelector((state) => state.filter.present.isChildren);
  const childrenAge = 1; //useAppSelector((state) => state.filter.present.childrenAge);
  const [filteredSpecialties, setFilteredData] = useState<SpecialtyUI[]>([]);

  // useEffect(() => {
  //   setFilteredData(
  //     specialties.map((specialty) => {
  //       let doctorCount = 0;
  //       specialty.specialities.forEach((subspecialty) => {
  //         subspecialty.doctors.forEach((doctor) => {
  //           if (
  //             childrenAge !== undefined &&
  //             doctor.isChildrenDoctor &&
  //             doctor.serveChildrenFrom <= childrenAge &&
  //             doctor.serveChildrenTo >= childrenAge
  //           ) {
  //             doctorCount++;
  //           }
  //         });
  //       });
  //       specialty.childrenGeneral = doctorCount;
  //       return specialty;
  //     }),
  //   );
  // }, [childrenAge, specialties]);

  const handleMenuClick = (item: Specialty) => {
    dispatch(
      setSpecialty({
        idFerSpeciality: item.idFerSpeciality,
        milaSpecialityName: item.milaSpecialityName,
        minServiceCost: item.minServiceCost,
        maxServeAge: item.maxServeAge,
        minServeAge: item.minServeAge,
        doctorCount: item.doctorCount,
      }),
    );
    dispatch(setServiceRequestType(serviceType));
    dispatch(goToPage('doctors'));
  };

  return (
    <ListComponent
      containerClass={styles.ListContainer}
      data={specialties}
      renderFunc={(item) => (
        <List.Item onClick={() => handleMenuClick(item)} className={styles.ListItem}>
          <Row className={styles.SpecialtyRow}>
            <Col className={styles.LeftCol}>
              <Typography className={styles.SpecialityName}>{item.milaSpecialityName}</Typography>
              <Row className={styles.InnerRow}>
                <Typography className={styles.DoctorAmount}>{item.doctorCount}</Typography>
                {/* <Col className={styles.TagContainer}>
                    <CustomTag className={styles.DateTag}>{getDateLabel(item.earliestDate)}</CustomTag>
                  </Col> */}
              </Row>
            </Col>
            <Col className={styles.RightCol}>
                <Typography className={styles.Price}>
                  от {item.minServiceCost}
                  <RubleSign className={styles.Ruble} />
                </Typography>

              <CaretRight className={'IconFill'} />
            </Col>
          </Row>
        </List.Item>
      )}
    />
  );
};
